import {
    APPEND_PAGE as SOURCE_APPEND_PAGE,
    appendPage as sourceAppendPage,
    UPDATE_LOAD_STATUS as SOURCE_UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS as SOURCE_UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS as SOURCE_UPDATE_PRODUCT_LIST_ITEMS,
    updateLoadStatus as sourceUpdateLoadStatus,
    updatePageLoadingStatus as sourceUpdatePageLoadingStatus,
    updateProductListItems as sourceUpdateProductListItems
} from 'SourceStore/ProductList/ProductList.action';

// TODO: implement APPEND_PAGE
export const APPEND_PAGE = SOURCE_APPEND_PAGE;

// TODO: implement UPDATE_PRODUCT_LIST_ITEMS
export const UPDATE_PRODUCT_LIST_ITEMS = SOURCE_UPDATE_PRODUCT_LIST_ITEMS;

// TODO: implement UPDATE_LOAD_STATUS
export const UPDATE_LOAD_STATUS = SOURCE_UPDATE_LOAD_STATUS;

// TODO: implement UPDATE_PAGE_LOAD_STATUS
export const UPDATE_PAGE_LOAD_STATUS = SOURCE_UPDATE_PAGE_LOAD_STATUS;

export const PRESELECT_PRODUCT_CARD_COLOR = 'PRESELECT_PRODUCT_CARD_COLOR';

export const RESET_COLOR = 'RESET_CARD_COLOR';

// TODO: implement appendPage
export const appendPage = sourceAppendPage;

// TODO: implement updateProductListItems
export const updateProductListItems = sourceUpdateProductListItems;

// TODO: implement updateLoadStatus
export const updateLoadStatus = sourceUpdateLoadStatus;

// TODO: implement updatePageLoadingStatus
export const updatePageLoadingStatus = sourceUpdatePageLoadingStatus;

/** @namespace Frontend/Store/ProductList/Action/preseSelectProductCardColor */
export const preseSelectProductCardColor = (value) => ({
    type: PRESELECT_PRODUCT_CARD_COLOR,
    value
});

/** @namespace Frontend/Store/ProductList/Action/resetColor */
export const resetColor = () => ({
    type: RESET_COLOR
});
