import {
    defaultConfig as sourceDefaultConfig
} from 'SourceStore/ProductList/ProductList.reducer';
import {
    APPEND_PAGE,
    PRESELECT_PRODUCT_CARD_COLOR,
    RESET_COLOR,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS
} from 'Store/ProductList/ProductList.action';
import { getIndexedProducts } from 'Util/Product';

/** @namespace Frontend/Store/ProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    pages: {},
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    currentArgs: {},
    preselectColorValue: ''
});

// TODO: implement defaultConfig
export const defaultConfig = sourceDefaultConfig;

/** @namespace Frontend/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        isLoading,
        args: currentArgs,
        value
    } = action;

    switch (type) {
    case APPEND_PAGE:
        return {
            ...state,
            isPageLoading: false,
            pages: {
                ...state.pages,
                [currentPage]: getIndexedProducts(initialItems)
            }
        };

    case UPDATE_PRODUCT_LIST_ITEMS:
        return {
            ...state,
            currentArgs,
            isLoading: false,
            totalItems,
            totalPages,
            pages: { [currentPage]: getIndexedProducts(initialItems) }
        };

    case UPDATE_PAGE_LOAD_STATUS:
        return {
            ...state,
            isPageLoading: true
        };

    case UPDATE_LOAD_STATUS:
        return {
            ...state,
            isLoading
        };
    case PRESELECT_PRODUCT_CARD_COLOR:
        return {
            ...state,
            preselectColorValue: value
        };
    case RESET_COLOR:
        return {
            ...state,
            preselectColorValue: ''
        };

    default:
        return state;
    }
};

export default ProductListReducer;
